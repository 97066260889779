import { TComponentsOverrides, TPaletteOverrides } from '../branding.types';

export const getMuiDrawerStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiDrawer: {
    styleOverrides: {
      root: {
        '&:has(.MuiDrawer-paperAnchorRight)': {
          right: 0,
          left: 'unset'
        }
      },
      paper: {
        backgroundColor: palette.secondary[1000]
      }
    }
  }
});
