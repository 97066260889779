import Joi from 'joi';
import { TLoginFormValues } from './LoginForm.types';

export const getLoginFormDefaultValues = (): TLoginFormValues => ({
  areCredentialsSaved: true,
  username: '',
  password: ''
});

export const getLoginFormSchema = () => {
  const usernameRequiredMessage = 'Username is required';
  const passwordRequiredMessage = 'Password is required';
  return Joi.object({
    areCredentialsSaved: Joi.boolean(),
    username: Joi.string().required().messages({
      'string.base': usernameRequiredMessage,
      'any.required': usernameRequiredMessage,
      'string.empty': usernameRequiredMessage
    }),
    password: Joi.string().required().messages({
      'string.base': passwordRequiredMessage,
      'any.required': passwordRequiredMessage,
      'string.empty': passwordRequiredMessage
    })
  });
};
