import { Controller, useFormContext } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Label } from '../../generic/Label';
import PasswordTextField from '../../components/PasswordTextField';

type TRHFTextFieldProps = TextFieldProps & {
  name: string;
  label?: string;
  tooltipText?: string;
  dataTestId?: string;
  // By default, the validation is triggered on submit
  triggerValidationOnChange?: boolean;
  usePasswordEyeIcon?: boolean;
};

function RHFTextField({
  name,
  helperText,
  type,
  disabled,
  label,
  tooltipText,
  required,
  dataTestId,
  triggerValidationOnChange,
  usePasswordEyeIcon,
  ...other
}: TRHFTextFieldProps) {
  const {
    control,
    trigger,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const commonProps = {
          ...field,
          fullWidth: true,
          error: !!error,
          helperText: error ? error?.message : helperText,
          disabled: disabled || isSubmitting,
          label: label ? <Label text={label} tooltipText={tooltipText} required={required} /> : undefined,
          onWheel: (e: React.WheelEvent) => e.target instanceof HTMLElement && e.target.blur(),
          ...other,
          inputProps: {
            ...(other.inputProps ?? {}),
            'data-test-id': dataTestId
          }
        };

        const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          if (type === 'number' && !usePasswordEyeIcon) {
            field.onChange(value ? Number(value) : null);
          } else {
            field.onChange(value);
          }
          if (triggerValidationOnChange) {
            await trigger(name);
          }
        };

        const parsedValue = field.value;

        return usePasswordEyeIcon ? (
          <PasswordTextField {...commonProps} value={parsedValue} onChange={handleChange} />
        ) : (
          <TextField {...commonProps} type={type} value={parsedValue} onChange={handleChange} />
        );
      }}
    />
  );
}

export default RHFTextField;
