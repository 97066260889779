import { TComponentsOverrides } from '../branding.types';

export const getMuiMenuStyles = (): TComponentsOverrides => ({
  MuiMenu: {
    styleOverrides: {
      root: {
        '& .MuiMenu-paper': {
          marginTop: '4px'
        },

        '&.MuiMenu-select': {
          '& .MuiMenu-list': {
            maxWidth: '296px',
            maxHeight: '260px',
            overflowY: 'scroll'
          }
        }
      }
    }
  }
});
