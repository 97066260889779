import { NavLink } from 'react-router-dom';
import style from './Navigation.module.css';
import { ReactNode } from 'react';

type withNavigationItemPropsType = {
  color?: string;
  active?: boolean;
  activeColor?: string;
  to: string;
};

// eslint-disable-next-line
export const withNavigationItem = (icon: ReactNode) => (props: withNavigationItemPropsType) => {
  return (
    <NavLink
      to={props.to}
      className={style.navigationIcon}
      activeClassName={style.activeNavigationIcon}
      // @ts-expect-error TS(2322): Type '() => boolean | undefined' is not assignable...
      isActive={() => props.active}
    >
      {icon}
    </NavLink>
  );
};
