import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useLocation } from 'react-router-dom';
import { config } from '../config';
import HttpStatusCode from 'http-status-codes';
import { useAuth } from '../auth/Auth.context';

type ReactQueryProviderProps = {
  children: React.ReactNode;
};

export function ReactQueryProvider({ children }: ReactQueryProviderProps) {
  const location = useLocation();
  const { userLogout } = useAuth();

  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSuccess: (error: any) => {
              if (location.pathname !== config.routes.login) {
                if (error?.status === HttpStatusCode.UNAUTHORIZED) {
                  userLogout();
                }
              }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
              console.log(error);
              if (location.pathname !== config.routes.login) {
                // if (error.status === HttpStatusCode.UNAUTHORIZED) {
                userLogout();
                // }
              }
            }
          },
          mutations: {
            // Set global options for all mutations
            onError: (error) => {
              console.log(error);
            }
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
