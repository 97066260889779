import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import style from './Navigation.module.css';
import { config } from '../../config';

export default function UsersNavItem() {
  return (
    <NavLink
      to={config.routes.users}
      className={cx([style.navLink, 'callout-default'])}
      activeClassName={cx([style.activeNavLink, 'callout-default'])}
    >
      Users
    </NavLink>
  );
}
