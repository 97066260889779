import { TPaletteOverrides, TComponentsOverrides } from '../branding.types';

export const getMuiChipStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: palette.secondary[800],
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        color: palette.secondary[100],
        padding: '4px 6px 4px 12px',
        gap: '8px',

        '& .MuiSvgIcon-root': {
          margin: 0,
          color: palette.secondary[100],
          backgroundColor: palette.secondary[900],
          borderRadius: '50%',
          padding: '2px',
          height: '24px',
          width: '24px'
        },

        '&:hover': {
          backgroundColor: palette.secondary[800],
          textDecoration: 'underline',
          textUnderlineOffset: '3px',

          '& .MuiSvgIcon-root': {
            color: palette.error[600]
          }
        }
      },
      label: {
        padding: 0
      }
    }
  }
});
