import * as React from 'react';
import { useMutation } from 'react-query';
import { config } from '../../config';
import axios from 'axios';
import { getJsonConvert } from '../../shared/utilities/json-convert';
import { AuthRequest } from '../../shared/models/auth/AuthRequest';
import { showErrorNotification } from '../../shared/utilities/notification';
import { AuthResponse } from '../../shared/models/auth/AuthResponse';

export default function useUserAuthentication() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const url = config.api.auth.userAuth;

  return useMutation(
    (payload: AuthRequest) => axios.post(url, payload).then((response) => jsonConvert.deserializeObject(response.data, AuthResponse)),
    {
      onSuccess: (response) => {
        return new Promise((resolve) => {
          resolve(response);
        });
      },
      onError: () => {
        showErrorNotification('Login failed', 'Wrong username or password');
      }
    }
  );
}
