export enum EIcon {
  ChevronRight = 'ChevronRight',
  CheckCircleOutlined = 'CheckCircleOutlined',
  Close = 'Close',
  ChipClose = 'ChipClose',
  ClipboardPlus = 'ClipboardPlus',
  DotDotDotVertical = 'DotDotDotVertical',
  DotsDrag = 'DotsDrag',
  ExternalLink = 'ExternalLink',
  GoTo = 'GoTo',
  Lock = 'Lock',
  LockUnlocked = 'LockUnlocked',
  Home = 'Home',
  MessageAlertSquare = 'MessageAlertSquare',
  Settings04 = 'Settings04',
  Edit16 = 'Edit16',
  EyeSlashed = 'EyeSlashed',
  Delete = 'Delete',
  Plus = 'Plus',
  Minimize01 = 'Minimize01',
  Refresh = 'Refresh',
  Expand01 = 'Expand01',
  Eye = 'Eye',
  Pause = 'Pause',
  Start = 'Start',
  Edit01 = 'Edit01',
  ActionClose = 'ActionClose',
  InfoCircle = 'InfoCircle',
  Save02 = 'Save02',
  ActionSearch = 'ActionSearch',
  ChevronUp = 'ChevronUp',
  ChevronDown = 'ChevronDown',
  FilterLines = 'FilterLines',
  RefreshCCW01 = 'RefreshCCW01',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Success = 'Success',
  FaceSmile = 'FaceSmile',
  Feedback = 'Feedback',
  AlertTriangle = 'AlertTriangle',
  ApiOutlined = 'ApiOutlined',
  Download01 = 'Download01',
  Calendar = 'Calendar',
  ActivityHeart = 'ActivityHeart',
  Folder = 'Folder',
  FolderPlus = 'FolderPlus',
  Hand = 'Hand',
  Pin = 'Pin',
  PinLeft = 'PinLeft',
  Columns = 'Columns',
  Layout = 'Layout',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  MinusCircle = 'MinusCircle',
  PlusCircle = 'PlusCircle',
  Copy = 'Copy',
  Code02 = 'Code02',
  File04 = 'File04',
  LinkExternal01 = 'LinkExternal01',
  Shuffle01 = 'Shuffle01',
  DeleteArrow = 'DeleteArrow',
  Play = 'Play',
  Switch = 'Switch',
  CaretUp = 'CaretUp',
  CaretDown = 'CaretDown',
  ThreeDots = 'ThreeDots',
  SwapRight = 'SwapRight',
  ChevronLeft = 'ChevronLeft',
  SQL='SQL',
  Java='Java'
}
