import { TPaletteOverrides, TComponentsOverrides } from '../branding.types';

export const getMuiCardStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiCard: {
    styleOverrides: {
      root: {
        border: `1px solid ${palette.secondary[700]}`,
        color: palette.secondary[100]
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: '24px'
      }
    }
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: '16px 24px',
        borderTop: `1px solid ${palette.secondary[800]}`,
        background: palette.secondary[1000],
        gap: 3
      }
    }
  }
});
