import React, { Suspense } from 'react';
import s from './App.module.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from '../Routes';
import { AppProvider } from './App.context';
import { AuthProvider } from '../auth/Auth.context';
import { ReactQueryProvider } from 'src/requests/react-query-provider';
import { SnackbarProvider } from 'notistack';
import ToastNotification from '../shared/components/Notifications';
import ErrorBoundary from '../shared/components/ErrorBoundary/ErrorBoundary';
import { ThemeProvider } from '@mui/material/styles';
import muiTheme from '../config/muiTheme';
import { Layout as LayoutAntd } from 'antd';
import { Spinner } from '../shared/generic/Spinner';

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        Components={{
          success: ToastNotification,
          error: ToastNotification,
          warning: ToastNotification,
          info: ToastNotification
        }}
        classes={{
          containerRoot: 'notistack-container'
        }}
      >
        <AppProvider>
          <Router>
            <AuthProvider>
              <ReactQueryProvider>
                <Suspense fallback={<Spinner isOverlay />}>
                <LayoutAntd className={s.layout}>
                  <ErrorBoundary>
                    <Routes />
                  </ErrorBoundary>
                </LayoutAntd>
                </Suspense>
              </ReactQueryProvider>
            </AuthProvider>
          </Router>
        </AppProvider>
      </SnackbarProvider>
    </ThemeProvider>

  );
}

export default App;
