import { ButtonProps } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import StyledRHFSubmitButton from './StyledRHFSubmitButton.styles';
import { Spinner } from '../../generic/Spinner';

export type TRHFSubmitButtonProps = ButtonProps & {
  text: string;
};

function RHFSubmitButton({ text, disabled, variant = 'contained', color = 'primary', ...other }: TRHFSubmitButtonProps) {
  const {
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <StyledRHFSubmitButton
      variant={variant}
      color={color}
      type="submit"
      endIcon={isSubmitting ? <Spinner /> : null}
      disabled={disabled || isSubmitting}
      {...other}
    >
      {text}
    </StyledRHFSubmitButton>
  );
}

export default RHFSubmitButton;
