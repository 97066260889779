import { AppBar, AppBarProps, styled } from '@mui/material';

const StyledHeader = styled(AppBar)<AppBarProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.palette.secondary[800],
  borderBottom: `1px solid ${theme.palette.secondary[700]}`,
  height: '64px',
  padding: '0px 16px 0px 8px',
  zIndex: 999,

  '& .MuiTabs-root .MuiTabs-flexContainer': {
    gap: '40px',
    marginLeft: '44px',
    height: '100%',
    overflow: 'visible',
    borderBottom: 0
  },

  '& .MuiTabs-root': {
    overflow: 'visible',
    height: '100%'
  },

  '& .menu-dropdown': {
    height: '100%',

    '& .menu-dropdown-trigger': {
      fontSize: '14px',
      fontWeight: 600,
      height: '100%'
    }
  },

  '& .MuiTab-root': {
    overflow: 'visible',
    zIndex: 2,
    alignItems: 'center'
  },

  '& .MuiTabs-scroller': {
    overflow: 'visible !important',
    height: '100%'
  },

  '& > *': {
    height: '100%'
  },

  '& .logo-wrapper': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  '& .right-menu': {
    height: '100%'
  },

  '& .MuiMenuItem-root.Mui-disabled': {
    opacity: 1
  }
}));

export default StyledHeader;
