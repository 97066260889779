import { enqueueSnackbar } from 'notistack';
import { TActionButtonProps } from '../components/Notifications';

export function showSuccessNotification(
  message: string,
  subtitle: string,
  description?: string,
  actionButton?: TActionButtonProps
) {
  enqueueSnackbar('', {
    variant: 'success',
    title: message,
    subtitle: subtitle,
    description: description ?? '',
    actionButton: actionButton
  });
}

export function showInfoNotification(message: string, subtitle: string, description?: string, actionButton?: TActionButtonProps) {
  enqueueSnackbar('', {
    variant: 'info',
    title: message,
    description: description ?? '',
    subtitle: subtitle,
    actionButton: actionButton
  });
}

export function showErrorNotification(
  message: string,
  subtitle: string,
  description?: string,
  actionButton?: TActionButtonProps
) {
  enqueueSnackbar('', {
    variant: 'error',
    title: message,
    subtitle: subtitle,
    description: description ?? '',
    actionButton: actionButton
  });
}

export function showWarningNotification(
  message: string,
  subtitle: string,
  description?: string,
  actionButton?: TActionButtonProps
) {
  enqueueSnackbar('', {
    variant: 'warning',
    title: message,
    subtitle: subtitle,
    description: description ?? '',
    actionButton: actionButton
  });
}
