import { Redirect, Route, Switch } from 'react-router-dom';
import { useApp } from '../App/App.context';
import { RouteType } from '../shared/models/route/RouteType';
import { RouteItem } from './RouteItem';
import { useRouteMatch } from './useRouteMatch';
import { config } from '../config';
import Login from '../pages/Login/Login';
import { useAuth } from '../auth/Auth.context';
import PageNotFound from '../pages/NotFound/PageNotFound';
import { useAppHook } from '../App/App.hook';

export default function Routes() {
  useAppHook();

  const { state: authState } = useAuth();
  const { state: appState } = useApp();

  const { isRouteMatch } = useRouteMatch();

  return (
    <Switch>
      <Route path={config.routes.login}>
        <Login />
      </Route>

      <Route path={config.routes.unavailable}>
        <PageNotFound />
      </Route>

      <Route exact path="/" render={() => <Redirect to="/home" />} />

      {appState.routes.length > 0 &&
        authState.isLoggedIn &&
        appState.routes
          .map((route: RouteType) => {
            return isRouteMatch(window.location.pathname, route.path) ?
              <RouteItem key={route.path} route={route} /> : null;
          })}
      <Route path="*">{!authState.isLoggedIn ? <Redirect to={config.routes.login} /> : <Redirect to={config.routes.homepage} />}</Route>
    </Switch>
  );
}
