// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .style_content__6C6uE {
  overflow: auto;
}

.style_sideNavigation__7\\+8UL {
  margin-left: 52px;
}

.style_contentWrapper__0cWYy {
  margin-top: 64px;
  background-color: #091025;
}
`, "",{"version":3,"sources":["webpack://./src/Routes/style.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":["body .content {\n  overflow: auto;\n}\n\n.sideNavigation {\n  margin-left: 52px;\n}\n\n.contentWrapper {\n  margin-top: 64px;\n  background-color: #091025;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `style_content__6C6uE`,
	"sideNavigation": `style_sideNavigation__7+8UL`,
	"contentWrapper": `style_contentWrapper__0cWYy`
};
export default ___CSS_LOADER_EXPORT___;
