import { JsonObject, JsonProperty } from 'json2typescript';
import { User } from '../users/User';

@JsonObject('AuthResponse')
export class AuthResponse {
  @JsonProperty('accessToken', String)
  accessToken?: string = undefined;

  @JsonProperty('tokenType', String)
  tokenType?: string = undefined;

  @JsonProperty('user', User)
  user?: User = undefined;
}
