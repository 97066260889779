import { Stack, StackProps, styled } from '@mui/material';

const StyledLoginCard = styled(Stack)<StackProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary[900],
  width: '400px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.secondary[800]}`,
  padding: '40px',

  '.loginTitle': {
    color: theme.palette.secondary[100],
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px'
  },

  '& label': {
    color: `${theme.palette.secondary[100]} !important`
  }
}));

export default StyledLoginCard;
