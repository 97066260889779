import { ReactElement } from 'react';

import { RouteType } from '../shared/models/route/RouteType';
import s from '../App/App.module.css';
import Header from '../pages/Header';
import { Layout } from 'antd';
import cx from 'classnames';
import routeS from './style.module.css';
import { Footer } from '../shared/components/Footer/Footer';
import { Navigation } from '../pages/Navigation/Navigation';

const { Content } = Layout;

export function ContentContainer(props: { children: ReactElement; footer: ReactElement; hasSideNavigation: boolean}) {
  return (
    <Content className={cx([routeS.content, { [routeS.sideNavigation]: props.hasSideNavigation }])}>
      <div className={s.container}>{props.children}</div>
      {props.footer}
    </Content>
  );
}

type PropType = {
  route: RouteType;
};

export function RouteItem({ route }: PropType) {
  let footer = <Footer />;
  if (route.path === '/login') {
    footer = <></>;
  }
  const routeComponent = route.component ? <route.component /> : <></>;

  return (
    <>
      <Header />
      <Layout.Content className={cx({ [routeS.contentWrapper]: true })}>
        <Layout>
          {route.hasSideNavigation ? <Navigation /> : null}
          {route.layout ? (
            <route.layout>
              {routeComponent}
              {footer}
            </route.layout>
          ) : (
            <ContentContainer hasSideNavigation={route.hasSideNavigation ?? false} footer={footer}>
              {routeComponent}
            </ContentContainer>
          )}
        </Layout>
      </Layout.Content>
    </>
  );
}
