import { Button, Stack, Typography } from '@mui/material';
import StyledHeader from './StyledHeader.styles';
import { useHistory } from 'react-router-dom';
import { config } from 'src/config';
import nessLogo from 'src/ness.png';
import { useAuth } from '../../auth/Auth.context';
import { useCallback } from 'react';

function Header() {
  const history = useHistory();
  const { userLogout } = useAuth();

  const logoutUser = useCallback(async () => {
    userLogout();
  }, [userLogout]);
  return (
    <StyledHeader>
      <Stack direction="row" alignItems="center">
        <div className="logo-wrapper">
          <img
            src={nessLogo}
            style={{ cursor: 'pointer' }}
            height={40}
            alt={'Ness'}
            onClick={() => history.push(config.routes.homepage)}
            data-test-id="logo"
          />
        </div>
        <Typography variant="h4">
          On Cloud Streaming Platform
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={3}>
        <Button size="small" color="secondary" variant="outlined" onClick={logoutUser}>
          Logout
        </Button>
      </Stack>
    </StyledHeader>
  );
}

export default Header;
