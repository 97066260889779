import axios, { AxiosError, AxiosResponse } from 'axios';
import { config } from '../config';
import { CommonResponse } from '../shared/models/common/CommonResponse';
import { LOCAL_STORAGE_TOKEN_NAME } from '../auth/AuthConstants';

const INVALID_SESSION = 'Invalid session';


// eslint-disable-next-line
(axios.defaults as any).baseURL = config.backendUrl;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = config.backendUrl;
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
axios.defaults.headers.common.xsrfCookieName = 'xsrfCookieName';

const axiosInstanceForHealthCheck = axios.create();

export function logout() {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME);
  window.location.assign('/login');
}

axios.interceptors.response.use(
  (response: AxiosResponse<{ resource: { request: CommonResponse } }>) => {
    const reason = response.data?.resource?.request?.reason;
    if (reason != null && reason.indexOf(INVALID_SESSION) >= 0) {
      logout();
    }
    return response;
  },
  async (error: AxiosError) => {
    if (!error.response) {
      try {
        await axiosInstanceForHealthCheck.get(config.backendUrl);
        // Case when the backend is up but the request failed because of long response time
        return Promise.reject(error);
      } catch {
        // Case when the backend is down
        return Promise.reject(error);
      }
    }
    return Promise.reject(error.response);
  }
);
