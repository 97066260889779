import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('User')
export class User {
  @JsonProperty('id', Number)
  id?: number = undefined;

  @JsonProperty('username', String)
  username?: string = undefined;

  @JsonProperty('role', String)
  role?: string = undefined;

  @JsonProperty('permissions', [String])
  permissions?: string[] = [];
}
