import { TComponentsOverrides } from '../branding.types';

export const getMuiRadioStyles = (): TComponentsOverrides => ({
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: 0
      }
    }
  }
});
