import { Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useAuth } from '../../auth/Auth.context';
import useUserAuthentication from '../../requests/auth/useUserAuthentication';
import { TLoginFormValues } from './LoginForm/LoginForm.types';
import { AuthRequest } from '../../shared/models/auth/AuthRequest';
import { getLoginFormDefaultValues, getLoginFormSchema } from './LoginForm/LoginForm.utils';
import StyledLoginCard from './StyledLoginCard.styles';
import RHFTextField from '../../shared/forms/RHFTextField';
import FormProvider from '../../shared/forms/FormProvider';
import RHFSubmitButton from '../../shared/forms/RHFSubmitButton';
import logo from '../../ness.png';

export default function Login() {
  const { setAuthResponse } = useAuth();

  const theme = useTheme();

  const authMutation = useUserAuthentication();

  const onFinish = async (formValues: TLoginFormValues) => {
    await sendAuthRequest(formValues.username, formValues.password).then((authResponse) => {
      setAuthResponse(authResponse);
      console.log(authResponse);
    }).catch((error) => {
      console.log("Unexpected error", error);
    });
  };

  const sendAuthRequest = async (username: string, password: string) => {
    return await authMutation.mutateAsync(new AuthRequest(username, password));
  };

  const defaultValues = useMemo(() => getLoginFormDefaultValues(), []);
  const formSchema = useMemo(() => getLoginFormSchema(), []);

  const methods = useForm<TLoginFormValues>({
    defaultValues: defaultValues,
    resolver: joiResolver(formSchema)
  });

  return (
    <Stack justifyContent="center" alignItems="center" height="100vh" width="100vw" bgcolor={theme.palette.secondary[1000]}>
      <StyledLoginCard>
        <Stack direction="column">
          <Stack alignItems="center" width="100%" direction="column">
            <img src={logo} width="165" alt="logo" />
          </Stack>
          <Typography className="loginTitle">Login</Typography>
          <FormProvider<TLoginFormValues> methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <Stack sx={{ marginTop: '24px' }}>
              <RHFTextField
                name="username"
                label="Username"
                size="medium"
                required
                sx={{ marginBottom: '24px' }}
              />
              <RHFTextField
                name="password"
                label="Password"
                size="medium"
                required
                sx={{ marginBottom: '24px' }}
                usePasswordEyeIcon
              />
              <RHFSubmitButton color="primary" fullWidth text="Login" />
            </Stack>
          </FormProvider>
        </Stack>
      </StyledLoginCard>
    </Stack>
  );
}
