import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import * as AuthConstants from './AuthConstants';
import { AuthResponse } from '../shared/models/auth/AuthResponse';
import { logout } from '../requests/api';

// eslint-disable-next-line
export const AuthContext = createContext<AuthContextType>(null as any);

export type AuthStateType = {
  isLoggedIn: boolean;
  bearerToken: string;
  // makes the LeavePrompt visible when trying to log out
  isLoggingOut: boolean;
  // true by default, set to false when the LeavePrompt appears
  readyToLogOut: boolean;
};

export type AuthContextType = {
  state: AuthStateType;
  setAuthResponse: (authResponse: AuthResponse) => void;
  userLogout: () => void;
  getToken: () => string;
  getUsername: () => string;
  setIsLoggingOut: (value: boolean) => void;
  setReadyToLogOut: (value: boolean) => void;
};

// eslint-disable-next-line
export function AuthProvider(props: any) {
  const token = localStorage.getItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME);
  const bearerToken = localStorage.getItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME);

  const [state, setState] = useState<AuthStateType>({
    isLoggedIn: !!token,
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
    bearerToken,
    isLoggingOut: false,
    readyToLogOut: true
  });

  const setAuthResponse = useCallback((authResponse: AuthResponse) => {
    // @ts-expect-error TS(2345): Argument of type '(current: AuthStateType) => { is...
    setState((current) => ({
      ...current,
      isLoggedIn: true,
      bearerToken: authResponse.accessToken
    }));
    localStorage.setItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME, authResponse.accessToken ?? '');
    localStorage.setItem(AuthConstants.LOCAL_STORAGE_USERNAME, authResponse.user?.username ?? '');
  }, []);

  const clearState = useCallback(() => {
    localStorage.removeItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME);
    localStorage.removeItem(AuthConstants.LOCAL_STORAGE_REFRESH_TOKEN_NAME);
    // @ts-expect-error TS(2345): Argument of type '(current: AuthStateType) => { is...
    setState((current) => ({
      ...current,
      isLoggedIn: false,
      bearerToken: null,
      isLoggingOut: false,
      readyToLogOut: true
    }));
  }, []);

  const userLogout = useCallback(() => {
    clearState();
    logout();
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const setIsLoggingOut = useCallback((value: any) => {
    setState((current) => ({
      ...current,
      isLoggingOut: value
    }));
  }, []);

  // eslint-disable-next-line
  const setReadyToLogOut = useCallback((value: any) => {
    setState((current) => ({
      ...current,
      readyToLogOut: value
    }));
  }, []);

  const getToken = useCallback(() => localStorage.getItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME), []);

  const getUsername = useCallback(() => localStorage.getItem(AuthConstants.LOCAL_STORAGE_USERNAME), []);

  useEffect(
    function checkUsernameExistsInLocalStorage() {
      // username is a new field introduced in local storage, will need user to logout and login again if it doesn't exist
      if (!state.isLoggedIn) {
        return;
      }

      if (getUsername()) {
        return;
      }

      userLogout();
    },
    [state.isLoggedIn, getUsername, userLogout]
  );

  const authContext: AuthContextType = {
    state,
    setAuthResponse,
    userLogout,
    // @ts-expect-error TS(2322): Type '() => string | null' is not assignable to ty...
    getToken,
    // @ts-expect-error TS(2322): Type '() => string | null' is not assignable to ty...
    getUsername,
    setIsLoggingOut,
    setReadyToLogOut
  };

  const { children } = props;
  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
