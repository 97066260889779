import React from 'react';
import { Layout } from 'antd';
import styles from './styles.module.css';
import { Stack, Typography, useTheme } from '@mui/material';

export function Footer() {
  const theme = useTheme();

  const platformVersion = '1.0.1';

  return (
    <React.Suspense fallback={<></>}>
      {(() => {
        return <Layout.Footer className={styles.root}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="caption" color={theme.palette.secondary[200]}>
              <b> On Cloud Streaming Platform </b>
            </Typography>
            {platformVersion && (
              <Typography variant="caption" color={theme.palette.secondary[200]}>
                v{platformVersion}
              </Typography>
            )}
            <Typography variant="caption" color={theme.palette.secondary[200]}>
              © Copyright Ness. All rights reserved.
            </Typography>
          </Stack>
        </Layout.Footer>;
      })()}
    </React.Suspense>
  );
}
