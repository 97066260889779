let backendUrl = 'http://localhost:8080/api/v1';

if (process.env.REACT_APP_BACKEND_URL) {
  backendUrl = process.env.REACT_APP_BACKEND_URL + '/api/v1';
}

const defaultConfig = {
  backendUrl: localStorage.getItem('backendUrl') || backendUrl,
  api: {
    auth: {
      userAuth: '/auth/login',
    },
    userManagement: {
      user: '/user',
    },
    jobs: {
      job: '/job',
      jobById: (jobId: number) => `/job/${jobId}`,
      status: '/job/status',
      deploy: (jobId: number) => `/job/${jobId}/deploy`,
      cancel: (jobId: number) => `/job/${jobId}/cancel`,
    },
    files: {
      upload: '/file/upload',
      file: '/file',
      fileById: (fileId: string | number) => `/file/${fileId}`
    }
  },
  routes: {
    homepage: '/home',
    home: '/',
    users: '/users',
    clusters: '/clusters',
    jobs: {
      base: '/jobs',
      created: `/jobs/created`,
    },
    login: '/login',
    page404: '/404',
    unavailable: '/unavailable'
  },
  defaultDateTimeFormat: 'DD/MM/YYYY HH:mm',
  defaultLanguageId: 1,
  requestTimeout: 20000
};

export const config = defaultConfig;
