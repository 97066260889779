import { TComponentsOverrides, TPaletteOverrides } from '../branding.types';

export const getMuiTooltipStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color: palette.grey.A100,
        backgroundColor: palette.secondary.main,
        fontSize: '12px',
        lineHeight: '20px',
        fontWeight: 400,
        padding: '12px 16px 16px 16px',
        alignSelf: 'stretch',
        borderRadius: '4px',
        margin: '20px'
      },
      arrow: {
        color: palette.secondary.main
      }
    }
  }
});
