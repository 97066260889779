import { TPaletteOverrides, TComponentsOverrides } from '../branding.types';

export const getMuiTabsStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 'unset',
        '& .MuiTabs-flexContainer': {
          gap: '24px',
          borderBottom: `1px solid ${palette.secondary[700]}`
        }
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: palette.secondary[300],
        padding: '0px',
        minHeight: '40px',
        minWidth: 'unset',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '24px',
        textTransform: 'none',
        alignItems: 'flex-start',
        flexDirection: 'row',

        '& > *': {
          color: palette.secondary[300]
        },

        '&.Mui-selected': {
          color: palette.secondary[0],

          '& > *': {
            color: palette.secondary[0]
          }
        },

        '&:hover': {
          color: palette.secondary[0],

          '& > *': {
            color: palette.secondary[0]
          }
        }
      }
    }
  }
});
