import * as React from 'react';
import { matchPath, NavLink } from 'react-router-dom';
import cx from 'classnames';
import style from './Navigation.module.css';
import { config } from '../../config';

export default function JobsNavItem() {

  const isJobsItemActive = React.useCallback((_: any, location: any) => {
    return !!matchPath(location.pathname, { path: config.routes.jobs.base });

  }, []);

  return (
    <NavLink
      to={config.routes.jobs.base}
      className={cx([style.navLink, 'callout-default'])}
      activeClassName={cx([style.activeNavLink, 'callout-default'])}
      isActive={isJobsItemActive}
    >
      Jobs
    </NavLink>
  );
}
