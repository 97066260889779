import { TPaletteOverrides, TComponentsOverrides } from '../branding.types';

export const getMuiSkeletonStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiSkeleton: {
    styleOverrides: {
      root: {
        background: palette.secondary[900]
      },
      rectangular: {
        borderRadius: '4px'
      }
    }
  }
});
