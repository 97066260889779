import { matchPath, useLocation } from 'react-router-dom';
import { NAVIGATION_KEYS } from './Navigation';
import { config } from '../../config';

export function useActiveNavigationItem() {
  const location = useLocation();

  if (matchPath(location.pathname, { path: config.routes.homepage })) {
    return NAVIGATION_KEYS.HOMEPAGE;
  }
  if (matchPath(location.pathname, { path: config.routes.clusters })) {
    return NAVIGATION_KEYS.CLUSTERS;
  }
  if (matchPath(location.pathname, { path: config.routes.jobs.base })) {
    return NAVIGATION_KEYS.JOBS;
  }
  if (matchPath(location.pathname, { path: config.routes.users })) {
    return NAVIGATION_KEYS.USERS;
  }
}
