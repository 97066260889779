import * as React from 'react';
import { config } from 'src/config';
import { RouteType } from '../shared/models/route/RouteType';

const Homepage = React.lazy(() => import('src/pages/Home'));
const PageNotFound = React.lazy(() => import('src/pages/NotFound'));
const UserManagement = React.lazy(() => import('src/pages/UserManagement'));
const Clusters = React.lazy(() => import('src/pages/Clusters'));
const Jobs = React.lazy(() => import('src/pages/Jobs'));

export const STATIC_ROUTES: Array<RouteType> = [
  {
    name: 'Home',
    title: 'OCS Platform',
    path: config.routes.homepage,
    component: Homepage,
    isHome: true,
    hasSideNavigation: true,
    renderOnlyChildren: false,
    isPrivate: true
  },
  {
    name: 'Clusters',
    title: 'OCS Clusters',
    path: config.routes.clusters,
    component: Clusters,
    isHome: false,
    hasSideNavigation: true,
    renderOnlyChildren: false,
    isPrivate: true
  },
  {
    name: 'Jobs',
    title: 'OCS Jobs',
    path: config.routes.jobs.base,
    component: Jobs,
    isHome: false,
    hasSideNavigation: true,
    renderOnlyChildren: false,
    isPrivate: true
  },
  {
    name: 'User management',
    title: 'User management',
    path: config.routes.users,
    component: UserManagement,
    hasSideNavigation: true,
    renderOnlyChildren: false,
    isPrivate: true
  },
  {
    name: 'Not found',
    title: 'Not found',
    path: '*',
    component: PageNotFound,
    isPrivate: false
  }
];
