import { config } from 'src/config';
import { Button, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useApp } from '../../App/App.context';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';

type TPageNotFoundProps = {
  action?: () => void;
};

function PageNotFound({ action }: TPageNotFoundProps) {
  const { state: appState } = useApp();
  const history = useHistory();
  if (appState.routes.length > 0 && appState.dynamicRoutesLoaded) {
    return (
      <Stack alignItems="center" textAlign="center" gap="16px" padding="24px" mt="124px">
        <Typography variant="body1" fontWeight="600">
          Oops!
        </Typography>
        <Typography fontSize="272px">
          <SentimentVeryDissatisfiedOutlinedIcon fontSize="inherit" />
        </Typography>
        <Typography className="pageNotFound">Page not found</Typography>
        <Typography variant="body2" className="detailMessage">
          The link you followed may be broken or the page may have been removed
        </Typography>
        <Button variant="contained" color="primary"
                onClick={() => {
                  action ? action() : history.push(config.routes.homepage);
                }}>
          Take me home
        </Button>
      </Stack>
    );
  }

  return null;
}

export default PageNotFound;
