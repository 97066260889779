// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_container__yqiFM {
  padding: 16px 48px;
  min-height: calc(100vh - 137px);
}

.App_layout__-JADL {
  min-height: 100vh !important;
}

.App_notistack-container__FGQXg {
  margin-bottom: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/App/App.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  padding: 16px 48px;\n  min-height: calc(100vh - 137px);\n}\n\n.layout {\n  min-height: 100vh !important;\n}\n\n.notistack-container {\n  margin-bottom: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `App_container__yqiFM`,
	"layout": `App_layout__-JADL`,
	"notistack-container": `App_notistack-container__FGQXg`
};
export default ___CSS_LOADER_EXPORT___;
