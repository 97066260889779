import * as React from 'react';
import style from './Navigation.module.css';
import { Menu } from 'antd';
import { withNavigationItem } from './NavigationItem.hoc';
import { NAVIGATION_KEYS } from './Navigation';
import { useActiveNavigationItem } from './useActiveNavigationItem.hook';
import UsersNavItem from './UsersNavItem';
import HomepageNavItem from './HomepageNavItem';
import { config } from '../../config';
import ClustersNavItem from './ClustersNavItem';
import JobsNavItem from './JobsNavItem';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

const iconItemStyle = {
  overflow: 'visible',
  height: 'auto',
  lineHeight: 'normal',
  marginTop: 24,
  padding: 0
};

type DecisionIntelligenceNavItemsProps = {
  isExpanded: boolean;
};
export default function NavItems({ isExpanded }: DecisionIntelligenceNavItemsProps) {
  const activeKey = useActiveNavigationItem();
  const [iconItems, setIconItems] = React.useState([]);

  const HomepageItem = withNavigationItem(<HomeOutlinedIcon sx={{  width: '24px' }} />);
  const ClustersItem = withNavigationItem(<WorkspacesOutlinedIcon sx={{  width: '24px' }} />);
  const JobsItem = withNavigationItem(<RocketLaunchOutlinedIcon sx={{  width: '24px' }} />);
  const UsersItem = withNavigationItem(<PeopleAltOutlinedIcon sx={{  width: '24px' }} />);

  React.useEffect(
    function computeNavigationIcons() {
      const items = [
        {
          icon: (
            <HomepageItem
              to={config.routes.homepage}
              active={activeKey === NAVIGATION_KEYS.HOMEPAGE}
              activeColor={'#F0F6FC'}
            />
          ),
          key: NAVIGATION_KEYS.HOMEPAGE,
          style: iconItemStyle
        },
        {
          icon: (
            <ClustersItem
              to={config.routes.clusters}
              active={activeKey === NAVIGATION_KEYS.CLUSTERS}
              activeColor={'#F0F6FC'}
            />
          ),
          key: NAVIGATION_KEYS.CLUSTERS,
          style: iconItemStyle
        },
        {
          icon: (
            <JobsItem
              to={config.routes.jobs.base}
              active={activeKey === NAVIGATION_KEYS.JOBS}
              activeColor={'#F0F6FC'}
            />
          ),
          key: NAVIGATION_KEYS.JOBS,
          style: iconItemStyle
        },
        {
          icon: (
            <UsersItem
              to={config.routes.users}
              active={activeKey === NAVIGATION_KEYS.USERS}
              activeColor={'#F0F6FC'}
            />
          ),
          key: NAVIGATION_KEYS.USERS,
          style: iconItemStyle
        }
      ];

      // @ts-expect-error TS(2345): Argument of type '{ icon: Element; key: JOB_NAVIGA...
      setIconItems(items);
    },
    // eslint-disable-next-line
    [setIconItems, activeKey]
  );

  return (
    <>
      <Menu
        style={{ borderRight: '0px', overflow: 'display' }}
        theme="dark"
        // @ts-expect-error TS(2769): No overload matches this call.
        selectedKeys={[activeKey]}
        defaultSelectedKeys={[NAVIGATION_KEYS.HOMEPAGE]}
        mode="vertical"
        items={iconItems}
      />
      {isExpanded ? (
        <div className={style.expandSider}>
          <HomepageNavItem />
          <ClustersNavItem />
          <JobsNavItem />
          <UsersNavItem />
        </div>
      ) : null}
    </>
  );
}
