import { createTheme } from '@mui/material';
import colors from './palette';
import { TPaletteOverrides } from './branding.types';
import { getComponentOverrides } from './components';

const ocsPalette: TPaletteOverrides = {
  primary: {
    light: colors.main.ness.tealGreen[300],
    main: colors.main.ness.tealGreen[400],
    dark: colors.main.ness.tealGreen[600],
    contrastText: colors.main.ness.tealGreen[800],
    ...colors.main.ness.tealGreen
  },
  secondary: {
    light: colors.main.ness.oceanBlue[700],
    main: colors.main.ness.oceanBlue[800],
    dark: colors.main.ness.oceanBlue[1000],
    contrastText: colors.main.ness.oceanBlue[200],
    ...colors.main.navyBlue
  },
  error: {
    light: colors.utilitarian.red[500],
    main: colors.utilitarian.red[600],
    dark: colors.utilitarian.red[800],
    contrastText: colors.main.navyBlue[0],
    ...colors.utilitarian.red
  },
  warning: {
    light: colors.utilitarian.orange[500],
    main: colors.utilitarian.orange[600],
    dark: colors.utilitarian.orange[800],
    contrastText: colors.main.navyBlue[0],
    ...colors.utilitarian.orange
  },
  info: {
    light: colors.utilitarian.blue[500],
    main: colors.utilitarian.blue[600],
    dark: colors.utilitarian.blue[800],
    contrastText: colors.main.navyBlue[0],
    ...colors.utilitarian.blue
  },
  success: {
    light: colors.utilitarian.green[400],
    main: colors.utilitarian.green[500],
    dark: colors.utilitarian.green[700],
    contrastText: '',
    ...colors.utilitarian.green
  },
  grey: {
    A100: colors.main.navyBlue[100],
    A200: colors.main.navyBlue[200],
    A400: colors.main.navyBlue[400],
    A700: colors.main.navyBlue[700],
    50: colors.main.navyBlue[100],
    ...colors.main.navyBlue
  },
  background: {
    default: colors.main.navyBlue[1000],
    paper: colors.main.navyBlue[900]
  },
  tertiary: {
    light: colors.main.ness.lavenderPurple[500],
    main: colors.main.ness.lavenderPurple[600],
    dark: colors.main.ness.lavenderPurple[800],
    contrastText: colors.main.ness.lavenderPurple[100],
    ...colors.utilitarian.blue
  },
};

const muiTheme = createTheme({
  palette: ocsPalette,
  components: {
    ...getComponentOverrides(ocsPalette),
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-weight: 100;
          src: url('assets/fonts/Inter-Thin.ttf') format('truetype');
        }
        @font-face {
          font-weight: 200;
          src: url('assets/fonts/Inter-ExtraLight.ttf') format('truetype');
        }
        @font-face {
          font-weight: 300;
          src: url('assets/fonts/Inter-Light.ttf') format('truetype');
        }
        @font-face {
          font-weight: 400;
          src: url('assets/fonts/Inter-Regular.ttf') format('truetype');
        }
        @font-face {
          font-weight: 500;
          src: url('assets/fonts/Inter-Medium.ttf') format('truetype');
        }
        @font-face {
          font-weight: 600;
          src: url('assets/fonts/Inter-SemiBold.ttf') format('truetype');
        }
        @font-face {
          font-weight: 700;
          src: url('assets/fonts/Inter-Bold.ttf') format('truetype');
        }
        @font-face {
          font-weight: 800;
          src: url('assets/fonts/Inter-ExtraBold.ttf') format('truetype');
        }
        @font-face {
          font-weight: 900;
          src: url('assets/fonts/Inter-Black.ttf') format('truetype');
        }
      `
    }
  }
});

export default muiTheme;
