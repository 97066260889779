import * as React from 'react';
import Sider from 'antd/es/layout/Sider';
import style from './Navigation.module.css';
import NavItems from './NavItems';

export enum NAVIGATION_KEYS {
  HOMEPAGE = 'homepage',
  CLUSTERS = 'clusters',
  JOBS = 'jobs',
  USERS = 'users',
}

export function Navigation() {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <Sider
      width={52}
      className={style.sider}
      onMouseEnter={() => {
        setIsExpanded(true);
      }}
      onMouseLeave={() => {
        setIsExpanded(false);
      }}
    >
      <NavItems isExpanded={isExpanded} />
    </Sider>
  );
}
