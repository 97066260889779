function Java() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384" id="Java--Streamline-Font-Awesome">
      <desc>Java Streamline Icon: https://streamlinehq.com</desc>
      <path
        d="M255.2402 233.8185c7.2029 -4.9245 17.1987 -9.1874 17.1987 -9.1874s-28.4441 5.1449 -56.7412 7.4969c-34.6181 2.8664 -71.8086 3.4544 -90.4773 0.9555 -44.1729 -5.8799 24.2547 -22.1232 24.2547 -22.1232s-26.5332 -1.764 -59.2403 13.9648c-38.5869 18.6687 95.5488 27.1946 165.0054 8.8934Zm-62.7682 -23.5932c-13.9648 -31.3841 -61.0777 -58.9462 0 -107.1616C268.6611 42.9415 229.589 3.84 229.589 3.84c15.8023 62.1067 -55.5653 80.9224 -81.3634 119.5094 -17.5663 26.3862 8.5994 54.6833 44.2464 86.8759ZM276.7019 80.72c0.0735 0 -128.7703 32.1926 -67.2516 103.0456 18.1542 20.8737 -4.7775 39.6895 -4.7775 39.6895s46.0839 -23.8137 24.9162 -53.5808c-19.7712 -27.7826 -34.912 -41.6004 47.1129 -89.1543Zm-4.4835 198.8149c-0.4016 0.7003 -0.8961 1.3431 -1.4699 1.9109 94.2992 -24.7691 59.6077 -87.3903 14.5528 -71.5145 -2.4334 0.8941 -4.536 2.5096 -6.0269 4.6304 2.6309 -0.9522 5.3347 -1.6896 8.0848 -2.205 22.7847 -4.7774 55.4918 30.5021 -15.1408 67.1782Zm34.6622 45.7899s10.6573 8.7463 -11.6864 15.5817c-42.5559 12.8624 -176.9856 16.7578 -214.3231 0.5145 -13.4503 -5.8064 11.7598 -13.9648 19.6977 -15.6553 8.2319 -1.7639 13.0093 -1.4699 13.0093 -1.4699 -14.9203 -10.5104 -96.5042 20.6532 -41.4534 29.5466 150.1144 24.4017 273.7104 -10.9514 234.7559 -28.5176Zm-164.3144 -30.4286c-57.8438 16.1697 35.206 49.5383 108.852 18.0072 -7.1826 -2.7904 -14.1162 -6.1835 -20.7267 -10.1429 -32.8541 6.2475 -48.0684 6.6884 -77.909 3.3075 -24.6222 -2.793 -10.2163 -11.1719 -10.2163 -11.1719Zm132.1512 71.441c-57.8437 10.8779 -129.2113 9.6284 -171.4732 2.646 0 -0.0735 8.6729 7.1294 53.2133 9.9958 67.7661 4.3365 171.8407 -2.4254 174.2662 -34.471 0 0 -4.704 12.1273 -56.0063 21.8292Zm-32.0456 -103.0456c-43.5114 8.3789 -68.7216 8.1584 -100.5466 4.8509 -24.6222 -2.5725 -8.5259 -14.4793 -8.5259 -14.4793 -63.7972 21.1677 35.4265 45.1284 124.5808 19.0362 -5.7621 -2.0346 -11.0418 -5.2374 -15.5083 -9.4078Z"
        fill="currentColor" strokeWidth="1"></path>
    </svg>
  );
}

export default Java;
