import { useEffect, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useApp } from './App.context';
import { getJsonConvert } from '../shared/utilities/json-convert';
import { useAuth } from '../auth/Auth.context';
import { config } from '../config';

export function useAppHook() {
  const {
    state: appState,
    setHasUserPermissions,
    setUserPermissions,
    setUserPermissionsLoaded
  } = useApp();
  const { state: authState } = useAuth();
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (appState.userPermissionsLoaded) {
      return;
    }
    if (authState.bearerToken === null) {
      setUserPermissions([]);
    } else {
      setUserPermissionsLoaded(true);
      setUserPermissions(['JOB_WRITE']);
    }
  }, [
    jsonConvert,
    authState.isLoggedIn,
    authState.bearerToken,
    appState.userPermissionsLoaded,
    setUserPermissionsLoaded,
    setUserPermissions,
    setHasUserPermissions,
    history
  ]);

  useEffect(() => {
    if (appState.userPermissionsLoaded) {
      setHasUserPermissions(true);
    }
  }, [setHasUserPermissions, appState.userPermissionsLoaded, appState.userPermissions]);

  useEffect(() => {
    if (appState.userPermissionsLoaded && location.pathname.startsWith('/login')) {
      if (appState.hasUserPermissions) {
        setTimeout(() => window.location.assign(config.routes.homepage), 100);
      } else {
        setTimeout(() => window.location.reload(), 1000);
      }
    }
  }, [history, appState.userPermissionsLoaded, appState.hasUserPermissions, location.pathname]);
}
